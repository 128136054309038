import { Navigate, Route, Routes } from "react-router-dom";

import "./assets/css/style.css";
import "./assets/css/custom.css";
import "aos/dist/aos.css";

import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import HomePage from "./pages/home";
import AboutUsPage from "./pages/aboutUs";
import GalleryPage from "./pages/gallery/index";
import ContactPage from "./pages/contact";
import FaqsPage from "./pages/faqs";
import BlogPage from "./pages/blog";
import JobsPage from "./pages/jobs";
import JobDetails from "./components/jobs/jobDetails/JobDetails";
import CompanyDetails from "./pages/companydetail/page";
import LoginPage from "./src/pages/login/page";
import SignUpPage from "./src/pages/registration/page";
import ProfilePage from "./src/pages/profile/page";
import { EmployeeTestimonial } from "./src/pages/testimonialemployee";
import { EmployeerTestimonial } from "./src/pages/testimonialemployer";
import FaqsPageEMP from "./pages/faqsEmp";

function App() {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/signin" element={<SignUpPage/>} />
        <Route path="/aboutUs" element={<AboutUsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/faqs" element={<FaqsPage />} />
        <Route path="/faqsEmp" element={<FaqsPageEMP />} />
        <Route path="/employeeTestimonial" element={<EmployeeTestimonial />} />
        <Route path="/employerTestimonial" element={<EmployeerTestimonial />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/jobs/:id" element={<JobDetails />} />
        <Route path="/company/:id" element={<CompanyDetails />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
