import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

function ApplyModel(props) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    resume: null
  });

  const [resumeError, setResumeError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /(\.pdf|\.doc)$/i;

    if (file && !allowedExtensions.exec(file.name)) {
      setResumeError('Only .pdf and .doc files are allowed');
      setFormData({ ...formData, resume: null });
    } else {
      setResumeError('');
      setFormData({ ...formData, resume: file });
    }
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('Phone', formData.phone);
    // data.append('resume', formData.resume);
    // if (!formData.resume) {
    //   setResumeError('Please upload a valid resume.');
    //   return;
    // }

    try {
        const res = await axios.post('https://jobportal-eosin-gamma.vercel.app/employer/jobs/apply-job', formData);
    } catch (error) {
        
    }

    // Here you can send the formData to your API or process it further
    console.log('Form Submitted', formData);

    // props.onHide(); // Close modal after form submission
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Job Apply
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your Phone number"
            //   pattern="[0-9]{10}"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formResume">
            <Form.Label>Resume</Form.Label>
            <Form.Control
              type="file"
            //   accept=".pdf,.doc"
            //   required
              onChange={handleFileChange}
            />
            {resumeError && <div style={{ color: 'red' }}>{resumeError}</div>}
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApplyModel;
