import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import img from "../../assets/images/gallery/office img 2.jpg"
export const EmployeerTestimonial = () => {
    return (
        <div>
            <div className="job_up_heading"></div>
            <h3 style={{ margin: "20px 0" }}>Employer Testimonial</h3>
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={6100}
            >
                <div>
                    <div className="shadow-effect">
                        <img
                            className="img-circle"
                            src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg"
                            alt=""
                        />
                        <p>
                            Dramatically maintain clicks-and-mortar solutions without
                            functional solutions. Completely synergize resource taxing
                            relationships via premier niche markets. Professionally
                            cultivate.
                        </p>
                    </div>
                </div>

                <div>
                    <div className="shadow-effect">
                        <img
                            className="img-circle"
                            src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg"
                            alt=""
                        />
                        <p>
                            Dramatically maintain clicks-and-mortar solutions without
                            functional solutions. Completely synergize resource taxing
                            relationships via premier niche markets. Professionally
                            cultivate.
                        </p>
                    </div>
                </div>
                <div>
                    <div className="shadow-effect">
                        <img
                            className="img-circle"
                            src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg"
                            alt=""
                        />
                        <p>
                            Dramatically maintain clicks-and-mortar solutions without
                            functional solutions. Completely synergize resource taxing
                            relationships via premier niche markets. Professionally
                            cultivate.
                        </p>
                    </div>
                </div>

                <div>
                    <div className="shadow-effect">
                        <img
                            className="img-circle"
                            src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg"
                            alt=""
                        />
                        <p>
                            Dramatically maintain clicks-and-mortar solutions without
                            functional solutions. Completely synergize resource taxing
                            relationships via premier niche markets. Professionally
                            cultivate.
                        </p>
                    </div>
                </div>
            </Carousel>
        </div>
    );
}