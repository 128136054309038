import { faqsApi } from "../../components/faqs/FaqsApi";

import { useState } from "react";
import faqsImg from "../../assets/images/faqs/faqs.jpg";
import Faqs from "../../components/faqs/Faqs";

const data = {
  pagename: "Faqs Employer",
  img: faqsImg,
};

function FaqsPage() {
  const [faqs, setFaqs] = useState(faqsApi.faqQuestion);
  return (
    <>
      <Faqs data={data} faqs={faqs} setFaqs={setFaqs} />
    </>
  );
}
export default FaqsPage;
