// import { Link } from "react-router-dom";
import { RxCaretDown, RxCaretRight } from "react-icons/rx";
import { SlCloudUpload } from "react-icons/sl";
import { BsGridFill, BsBriefcase } from "react-icons/bs";
import { FaCaretUp } from "react-icons/fa6";
import { PiUserCircleLight } from "react-icons/pi";
import { TbPencilMinus } from "react-icons/tb";
import { MdDeveloperMode } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { FaUniversity } from "react-icons/fa";

import logo from "../../../../assets/images/logo/henna-logo.png";
import { Link } from "react-router-dom";
// import { useState } from "react";

function Upperheader() {
  // const [fix, setFix] = useState(false);
  // function setFixed() {
  //   if (window.scrollY >= 300) {
  //     setFix(true);
  //   } else {
  //     setFix(false);
  //   }
  // }
  // window.addEventListener("scroll", setFixed);
  return (
    <>
      <section className="upper_header_sec">
        <header className="upper_header_main">
          <div className="logo_main_box">
            <div className="logo_container">
              <img src={'https://experteyeconsulting.com/wp-content/uploads/2021/03/Experteye_Consulting_LOGO_FINAL_-_SOCIAL_MEDIA_DP-e1710142777226-1024x280.png'} alt="" className="logo" />
            </div>
            <div className="category_box">
              <a href="" className="category_min">
                <BsGridFill className="mb-1 me-1" />
                Catergory
              </a>

              <div className="category_dropdown">
                <FaCaretUp className="caret_icon_min" />
                <ul className="category_item">
                  <li>
                    <a href="" className="category_item_box">
                      <div className="icon_box_category_list">
                        <TbPencilMinus className="category_list_icon" />
                      </div>
                      <div className="professon_text">
                        <h6>UI/UX Design</h6>
                        <small>12k+ jobs</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="" className="category_item_box">
                      <div className="icon_box_category_list">
                        <BsBriefcase className="category_list_icon" />
                      </div>
                      <div className="professon_text">
                        <h6>Marketing</h6>
                        <small>420k+ jobs</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="" className="category_item_box">
                      <div className="icon_box_category_list">
                        <TbAdjustmentsHorizontal className="category_list_icon" />
                      </div>
                      <div className="professon_text">
                        <h6>Editing</h6>
                        <small>3k+ jobs</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="" className="category_item_box">
                      <div className="icon_box_category_list">
                        <MdDeveloperMode className="category_list_icon" />
                      </div>
                      <div className="professon_text">
                        <h6>Development</h6>
                        <small>7k+jobs</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="" className="category_item_box">
                      <div className="icon_box_category_list">
                        <FiPhone className="category_list_icon" />
                      </div>
                      <div className="professon_text">
                        <h6>Telemarketing</h6>
                        <small>310k+jobs</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="" className="category_item_box">
                      <div className="icon_box_category_list">
                        <FaUniversity className="category_list_icon" />
                      </div>
                      <div className="professon_text">
                        <h6>Accounting</h6>
                        <small>10k+ jobs</small>
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="explore_feild mt-3">
                  <h6>Explore all feild</h6>
                  <a href="" className="explore_feild_caret">
                    <RxCaretRight className="feild_caret" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <nav className="nav">
            <ul className="nav_item">
              <li className="nav_item_list">
                <Link to="/home" className="hvr-underline-from-left1">
                  Home
                </Link>
                <div className="home_drodown">
                  <ul>
                    <li>
                      <a href="">Home1</a>
                    </li>
                    <li>
                      <a href="">Home2</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav_item_list">
                <Link to="/jobs" className="hvr-underline-from-left1">
                  Jobs
                </Link>

                {/* <div className="job_dropdown_wrapper">
                  <ul className="job_grid_list">
                    <li className="job_grid_item">
                      <a href="">Job Grid</a>
                    </li>
                    <li className="job_grid_item">
                      <a href="">Job Sidebar</a>
                    </li>
                    <li className="job_grid_item">
                      <a href="">Job Details</a>
                    </li>
                    <li className="job_grid_item">
                      <a href="">Job grid Map</a>
                    </li>
                  </ul>
                </div> */}
              </li>

              <li className="nav_item_list">
                <Link to="/aboutUs" className="hvr-underline-from-left1">
                  Companies
                </Link>
              </li>

              <li className="nav_item_list">
                <Link to="/aboutUs" className="hvr-underline-from-left1">
                  about us
                </Link>
              </li>

              <li className="nav_item_list">
                <Link to="/blog" className="hvr-underline-from-left1">
                  Blog
                </Link>
              </li>

              <li className="nav_item_list">
                <a href="" className="hvr-underline-from-left1">
                  employers
                  <RxCaretDown className="hvr_caret" />
                </a>
              </li>

              {/* <li className="nav_item_list">
                <Link to="/gallery" className="hvr-underline-from-left1">
                  Gallery
                </Link>
              </li> */}

              <li className="nav_item_list">
                <Link to="/contact" className="hvr-underline-from-left1">
                  contact us
                </Link>
              </li>

              <li className="nav_item_list">
                <Link to="/login" type="btn" className="bg-success btn_signup_sgn">
                  {/* <PiUserCircleLight className="user_icon" /> */}
                  login
                </Link>
              </li>
              <li className="nav_item_list">
                <Link to="/signin" type="btn" className="bg-success btn_signup_sgn">
                  {/* <PiUserCircleLight className="user_icon" /> */}
                  sign in
                </Link>
              </li>
              <li className="nav_item_list">
                <Link to="/profile" type="btn" className="bg-success btn_signup_sgn">
                  <PiUserCircleLight className="user_icon" />
                 Profile
                </Link>
              </li>
              {/* 
              <li className="nav_item_list">
                <a href="" type="btn" className="bg-warning btn_signup_upld">
                  upload CV <SlCloudUpload className="upload_icon" />
                </a>
              </li> */}
            </ul>
          </nav>
        </header>
      </section>
    </>
  );
}
export default Upperheader;
