import { Link } from "react-router-dom";
import img1 from "../../../assets/images/aboutus-img/about us 2.jpg";
import img2 from "../../../assets/images/aboutus-img/about-3.webp";
import aboutlogo from "../../../assets/images/aboutus-logo/about.png";

// import { Aos } from "aos";
import Aos from "aos";

import { useEffect } from "react";

function AboutUsHome() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="about_us_main_sec" data-aos="fade-right">
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="about_img">
                <div className="sec_box_one">
                  <img src={img1} alt="" className="img-1" />
                </div>
                <div className="sec_box_two">
                  <img src={img2} alt="" className="img-2" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutus_details_box">
                <img src={aboutlogo} alt="" />
                <h4 style={{ fontWeight: "400", color: "#000430" }}>
                  ExpertEye Consulting. derives its strength from the
                  inspiring wisdom and accomplishments of its Founder – Mr.
                  Aijaz-ul-Haque Siddique
                </h4>
                <p className="p-text">
                  ExpertEye Consulting, introduces ourselves as professional
                  Head-Hunters, International Recruitment Firms, Overseas
                  Recruitment Agencies, and HR Consultants in India. We have an
                  excellent track record of recruiting thousands of management
                  professionals, technical and non-technical personnel, and
                  skilled ...
                </p>
              </div>
              <div className="about_btn">
                <Link to="/aboutUs" className="about_more_btn">
                  About more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AboutUsHome;
